<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                v-on:keyup.enter="OnEnter()"
                dense
                filled
                class="mb-2"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >         
          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>

          <template v-slot:item.evaluate_s="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2>
                <v-icon>mdi-checkbox-marked-circle</v-icon> ส่งผลการประเมิน
              </h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"        
            >
              <h2><v-avatar left class="green darken-4 pa-1">
                   <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                </v-avatar>
              
                ครั้งที่ : <v-avatar right class="green darken-4 pa-1">
                  {{ item.last_time_ass }}
                </v-avatar>
              </h2>
            </v-chip>
            <v-chip
              v-else
              color="warning"             
            >
              <h2>
                <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                ไม่ได้เริ่มประเมิน
              </h2>
            </v-chip>
          </template>

       

          <template v-slot:item.prints="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2><v-icon>mdi-cube-send</v-icon> รายงานผล</h2>
            </v-chip>
            <v-chip v-else-if="item.last_time_ass === ''" color="warning">
              <h2>
                <v-icon class="pa-1">mdi-information-variant</v-icon>
                อยู่ระหว่างการประเมิน
              </h2>
            </v-chip>
            <v-chip v-else color="warning">
              <h2>
                <v-icon class="pa-1">mdi-information-variant</v-icon>
                อยู่ระหว่างการประเมิน
              </h2>
            </v-chip>
          </template>
          <template v-slot:item.date_app_now="{ item }">
            {{
              item.date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

         
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>
    </v-container>
      </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,    
    
      personnel_temporarys: [],    
      search: "",
      pagination: {},
      headers: [
        { text: "รหัสบัตรประชาชน", align: "left", value: "id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "สกุล", align: "left", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "กรรมการ", align: "center", value: "committee" },
        { text: "ระดับ", align: "center", value: "rang_level" },
        { text: "การประเมิน", align: "center", value: "evaluate_s" },
        { text: "คะแนน", align: "center", value: "score_s" },
        { text: "สถานะ", align: "center", value: "prints" },
        { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
        { text: "คำสั่งที่", align: "center", value: "order_app_now" },
        {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "date_app_now"
        },
        { text: "เกษียณ", align: "center", value: "retrire_year" },   
      ],            
    };
  },

  async mounted() {
    await this.personnel_temporaryQueryAll();
   
  },

  methods: {
   
    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          collegeVei: userSession.user_code,
          position_name: "ครูผู้ช่วย"
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
   
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    times_s() {
      let times = this.personnel_temporary_id_card.last_time;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "ครบการประเมิน";
      } else {
        result = 1;
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;

      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    }
  }
};
</script>
